const loaderEl = document.querySelector('.js-loader');

export function startLoader() {
  loaderEl.classList.add('isOpen');
}

export function finishLoader() {
  loaderEl.classList.remove('isOpen');
}

window.addEventListener('DOMContentLoaded', () => {
  setTimeout(() => {
    loaderEl.classList.remove('isOpen');
  }, 1000);
});
